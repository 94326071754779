* {
    margin: 0;
    padding: 0;
    /* font-family: "Space Mono", monospace; */
    /* font-family: "Poppins", sans-serif; */
    font-family: "Montserrat", sans-serif;
}

html, body {
    height:100%;
    font-size: 18px;
}

input {
    font-size: 18px;
}


.modal_background {
    position: fixed;
    background-color: rgba(0,0,0,0.75);
    width: 100%;
    height: 100%;
    top: 0px;
    z-index: 1000000000000000;
}

.modal_container {
    position: relative;
    background-color: white;
    width: 600px;
    padding: 20px;
    max-height: calc(100% - 80px);
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 20px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    box-shadow: var(--big_shadow);
}

.highlighted_text {
    cursor: pointer;
    color: rgb(0, 153, 255);
}

.highlighted_text:hover {
    color: rgb(195, 0, 255);
}

.selectable_image:hover {
    opacity: 0.8;
}

.clickable_text:hover {
    cursor: pointer;
    color: rgb(0, 153, 255);
}

.pop_over_shield {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    transition: transform 0.5s ease-out;
    animation: fade_in 0.5s ease-out forwards;
}

.pop_over_ad {
    position: fixed;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Offset by half the element's width and height */
    width: 500px;
    min-height: 200px;
    background-color: black;
    z-index: 10000;
    border-radius: 20px;
    border: 1px solid yellow;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    transition: transform 0.5s ease-out;
    animation: slide_up 0.5s ease-out forwards;
}

.pop_over_ad.hide {
    animation: slide_down 0.25s ease-out forwards;
}

@keyframes slide_up {
    0% {
        top: 100%;
        transform: translate(-50%, 100%);
    }
    100% {
        top: 50%;
        transform: translate(-50%, -50%);
    }
}


@keyframes fade_in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.8;
    }
}

@keyframes slide_down {
    from {
        top: 50%;
        transform: translate(-50%, -50%);
    }
    to {
        top: 100%;
        transform: translate(-50%, 100%);
    }
}

.visit_site_button {
    margin-top: 20px;
    width: 240px;
    margin-left: calc(50% - 120px);
    border-radius: 10px;
    font-size: 24px;
    height: 50px;
    line-height: 50px;
    font-weight: bold;
    text-align: center;
    color: black;
    background-color: #ffff00;
    cursor: pointer;
}

.visit_site_button:hover {
    opacity: 0.8;
}

.continue_reading_button {
    margin-top: 10px;
    width: 240px;
    margin-left: calc(50% - 120px);
    border-radius: 10px;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: white;
    text-decoration: underline;
    margin-bottom: 20px;
    cursor: pointer;
}

.continue_reading_button:hover {
    color: #00b5fa;
}

.pop_over_title {
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: -10px;
    width: calc(100% - 40px);
    color: white;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}

.pop_over_text {
    margin-top: -10px;
    margin-left: 20px;
    width: calc(100% - 40px);
    color: white;
    font-size: 18px;
    text-align: center;
}

.pop_over_close {
    position: absolute;
    top: 27px;
    left: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.pop_over_close:hover {
    opacity: 0.7;
}

.full_img {
    display: block;
    width: 100%;
}

@media (max-width: 640px) {

    .pop_over_ad {
        width: calc(100% - 20px);
    }

    .modal_container {
        padding: 20px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        max-height: none;
    }
}

table {
    width: calc(100% - 40px); /* Adjust width as needed */
    border-collapse: collapse; /* Ensures borders between cells are shared */
    margin: 20px; /* Adds some space around the table */
}
th, td {
    border: 1px solid black; /* Black border for each cell */
    padding: 8px; /* Padding inside cells */
    text-align: left; /* Align text to the left inside cells */
}
th {
    background-color: #f2f2f2; /* Light grey background for header cells */
}